<template>
    <div class="work-out">
        <van-tabs v-model="active">
            <van-tab title="基本信息" name="a">
                <WorkInfo/>
            </van-tab>
            <van-tab title="轨迹地图" name="b">
                <WorkMap/>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import WorkInfo from "../components/WorkOutApply-Normal/work-info.vue"
import WorkMap from "../components/WorkOutApply-Normal/work-map.vue"
export default {
    components: {
        WorkInfo,WorkMap
    },
    data() {
        return {
            active:'a'
        }
    },
}
</script>
<style lang='less' scoped>
.work-out{
    width: 100%;
    height: 100%;
    background-color: #fff;
    /deep/ .van-tabs{
        width: 100%;
        height: 100%;
        .van-tabs__wrap{
            width: 100%;
            height: 40px;
            border-bottom: 1px solid #eee;
        }
        .van-tab{
            color: #000!important;
            font-size: 14px!important;
        }
        .van-tabs__line{
            background-color: #000!important;
            height: 2.2px;
            width: 25px;
            bottom: 20px!important;
            // padding-bottom: 10px;
        }
        .van-tab--active{
            color: #000!important;
            font-weight: bold;
        }
        .van-tabs__content{
            width: 100%;
            height: calc(100% - 44px);
            background-color: rgb(248,248,248);
            .van-tab__pane{
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>